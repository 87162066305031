import './About.css';
import img1 from './img/about-img.jpg';
import img2 from './img/about-img2.jpg';
import {ReactComponent as RightArrowIcon} from './img/arrow-right.svg';
import {ReactComponent as TwitterIcon} from './img/twitter icon.svg';
import {ReactComponent as IgIcon} from './img/ig icon.svg';
import {ReactComponent as FbIcon} from './img/fb icon.svg';

function About() {
    return(
        <div id='about' className='container section'>
            <div className='section-title'>About</div>
            <div className='row justify-content-center'>
                <div className='col-12'>
                    <img src={img1} className='main-img-mobile img-fluid mb-5'></img>
                </div>
            </div>
            
            <div className='row'>
                <div className='col-lg-5 col-xs-12'>
                    <img src={img2} className='main-img mt-5'></img>
                </div>
                <div className='col-lg-7 col-xs-12'>
                    <div className='title'>Hola, mi gente! I'm...</div>
                    <div className='hero-text mb-1'>Tatiana</div>
                    <div className='emphasis pb-4'>
                        And I'm a poet, speaker, teaching artist, community advocate, and organizer based out of Washington DC. 
                    </div>
                    <div className='body-content mb-5'>
                     I graduated with a B.A. in English Literature from UMBC and an M.A. in Public Management with a focus on Nonprofit Management and Leadership at UMD. I'm also a VONA Voices alumna, having worked with Willie Perdomo and Danez Smith, and an Anaphora Arts Writing Residency Fellow. I've also had the honor of being named the 2020 Burns Whiskey Poetry Slam Champion and being the recipient of the 2023 CFA Practitioner Award.
                     <br></br>
                     <br></br>
                     Currently, you can find me performing, facilitating workshops, and hosting events in the greater Washington DC area, having previously done so across the United States and the Dominican Republic at venues including New York University and The Kennedy Center. And for the folks that prefer to connect digitally, I'm available on the usual social channels, so feel free to reach out and say hi. 

                    </div>
                    <div className='about-socials row justify-content-center mb-5'>
                        <a href='https://twitter.com/msauciana' target='_blank' className='col-lg-2 col-3'>
                            <TwitterIcon />
                        </a>
                        <a href='https://www.instagram.com/msauciana/?hl=en' target='_blank' className='col-lg-2 col-3 text-center'>
                            <IgIcon />
                        </a>
                        <a href='https://www.facebook.com/msauciana/' target='_blank' className='col-lg-2 col-3 text-end'>
                            <FbIcon />
                        </a>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-12'>
                    <div className='partners d-flex py-5 overflow-x-hidden justify-content-between'>
                        <div className='stat ms-5'>
                            <div className='big-text'>
                                2
                            </div>
                            <div className='med-text'>
                                Books authored
                            </div>    
                        </div>
                        <div className='stat mx-3'>
                            <div className='big-text'>
                                5
                            </div>
                            <div className='med-text'>
                                Anthologies available
                            </div>    
                        </div>
                        <div className='stat me-5'>
                            <div className='big-text'>
                                25+
                            </div>
                            <div className='med-text'>
                                Poems published
                            </div>    
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;