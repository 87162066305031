import './Footer.css';
import {ReactComponent as LogoMark} from './img/tfr-logo-mark.svg';
import {ReactComponent as TwitterIcon} from './img/twitter icon.svg';
import {ReactComponent as IgIcon} from './img/ig icon.svg';
import {ReactComponent as FbIcon} from './img/fb icon.svg';

function Footer() {
    return(
        <div className='d-flex justify-content-center footer px-5 py-5'>
            <div className='footer-content text-center'>
                <LogoMark className='footer-logo'/>
                <div className='tag-line py-4'>Para mi gente</div>
                <div className='socials d-flex justify-content-center'>
                    <a href='https://twitter.com/msauciana' target='_blank'>
                        <TwitterIcon />
                    </a>
                    <a href='https://www.instagram.com/msauciana/?hl=en' target='_blank'>
                        <IgIcon />
                    </a>
                    <a href='https://www.facebook.com/msauciana/' target='_blank'>
                        <FbIcon />
                    </a>
                    
                </div>
                <div className='copyrights py-4'>
                    <p>Copyright &#169; 2023 Tatiana Figueroa Ramirez</p>
                    <p>All rights reserved.</p>
                </div>
            </div>
        </div>
    );
}

export default Footer;