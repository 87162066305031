import './Works.css';
import React, { Component } from "react";
import Slider from "react-slick";
import {ReactComponent as BookIcon} from './img/book.svg';
import {ReactComponent as ChevRight} from './img/chevron-right.svg';
import {ReactComponent as ChevLeft} from './img/chevron-left.svg';
import {ReactComponent as LinkIcon} from './img/external-link.svg';
import PoemImg1 from './img/las-tias.png';
import PoemImg2 from './img/repeat-myself.png';
import Modal from './Modal';

export default class Works extends Component {
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }
  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false
    };

    return (
        
      <div id='works' className='section container'>
        <div className='section-title'>Works</div>
        <Slider ref={c => (this.slider = c)} {...settings} className='row'>
          {/* slider item - control w/ key */}
          {/* item 1 */}
          <div className="container" key={1}>
            <div className='row justify-content-between'>
                <div className='col-lg-5 col-xs-12 order-2 order-lg-1 mt-5'>
                    <div className='work-title mb-4'>
                      Things my Daughter Must Know
                    </div>
                    <div className='work-subtitle d-flex pb-4'>
                        <BookIcon className='me-2' />
                        <div>
                          Published in 
                          <a href='https://sincerelytatiana.bigcartel.com/product/coconut-curls-y-cafe-con-leche' target='_blank' className='ms-1'>
                            Coconut Curls y Café con Leche (August 2019)
                            <LinkIcon className='ms-1'/>
                          </a>
                        </div>
                    </div>
                    <div className='body-content'>
                    To the little Black and Brown girls living within us and as themselves. Remember you are beautiful no matter what this world might say.
                    </div>
                </div>
                <div className='col-lg-6 col-xs-12 order-1 order-lg-2 mt-lg-5'>
                  <div id='player1'></div>
                </div>
              </div>
          </div>

          <div className="container" key={2}>
            <div className='row justify-content-between'>
              <div className='col-lg-5 col-xs-12 order-2 order-lg-1 mt-5'>
                  <div className='work-title mb-4'>
                    We are Deathless
                  </div>
                  <div className='work-subtitle d-flex pb-4'>
                      <BookIcon className='me-2' />
                      <div>
                        Forthcoming in Exhume
                      </div>
                  </div>
                  <div className='body-content'>
                  My sisters we are an unstoppable force of beauty and power. Together, in sisterhood, we thrive. “Whatever happens, whatever happened (oh hey). We are deathless” - Ibeyi
                  </div>
              </div>
              <div className='col-lg-6 col-xs-12 order-1 order-lg-2 mt-lg-5'>
                <div id='player2'></div>
              </div>
            </div>
          </div>

          <div className="container" key={3}>
            <div className='row justify-content-between'>
              <div className='col-lg-5 col-xs-12 order-2 order-lg-1 mt-5'>
                  <div className='work-title mb-4'>
                    (after) won't you celebrate with me
                  </div>
                  <div className='work-subtitle d-flex pb-4'>
                      <BookIcon className='me-2' />
                      <div>
                        Forthcoming in Exhume
                      </div>
                  </div>
                  <div className='body-content'>
                  Lucille Clifton wrote “come celebrate with me that everyday something has tried to kill me and has failed.”
                  </div>
              </div>
              <div className='col-lg-6 col-xs-12 order-1 order-lg-2 mt-lg-5'>
                <div id='player3'></div>
              </div>
            </div>
          </div>

          <div className="container" key={4}>
            <div className='row justify-content-between'>
              <div className='col-lg-5 col-xs-12 order-2 order-lg-1 mt-5'>
                  <div className='work-title mb-4'>
                    Be Like Borinquen
                  </div>
                  <div className='work-subtitle d-flex pb-4'>
                      <BookIcon className='me-2' />
                      <div>
                        Forthcoming in Exhume
                      </div>
                  </div>
                  <div className='body-content'>
                  For those who need reminding they are overflowing with the beauty of our lands.
                  </div>
              </div>
              <div className='col-lg-6 col-xs-12 order-1 order-lg-2 mt-lg-5'>
                <div id='player4'></div>
              </div>
            </div>
          </div>

          <div className="container" key={5}>
            <div className='row justify-content-between'>
              <div className='col-lg-5 col-xs-12 order-2 order-lg-1 mt-5'>
                  <div className='work-title mb-4'>
                    To a Praying Puerto Rican Peasant
                  </div>
                  <div className='work-subtitle d-flex pb-4'>
                      <BookIcon className='me-2' />
                      <div>
                        Forthcoming in Exhume
                      </div>
                  </div>
                  <div className='body-content'>
                  To Lolita Lebrón
                  </div>
              </div>
              <div className='col-lg-6 col-xs-12 order-1 order-lg-2 mt-lg-5'>
                <div id='player5'></div>
              </div>
            </div>
          </div>

          <div className="container" key={6}>
            <div className='row justify-content-between'>
              <div className='col-lg-5 col-xs-12 order-2 order-lg-1 mt-5'>
                <div className='work-title mb-4'>
                  A conversation with the t&iacute;as
                </div>
                <div className='work-subtitle d-flex pb-4'>
                    <BookIcon className='me-2' />
                    <div>
                      Published in
                      <a href='https://www.acentosreview.com/specialissuejune2020/tatiana-figueroa-ramirez.html' target='_blank' className='ms-1'>
                        Acentos Review (2020)
                        <LinkIcon className='ms-1'/>
                      </a>
                    </div>
                </div>
                <div className='body-content'>
                We must call out the anti-Blackness found in the Latinx community. Blackness is pivotal in forming what many identify as Latinidad, so we must acknowledge that and we must assert the importance of Black lives.
                </div>
                <button type="button" className="modal-btn mt-4" data-bs-toggle="modal" data-bs-target="#las-tias">
                  Read poem
                </button>
              </div>
              <div className='col-lg-6 col-xs-12 order-1 order-lg-2 mt-lg-5'>
                <img src={PoemImg1} className='img-fluid border rounded-4'></img>
              </div>
            </div>
          </div>

          <div className="container" key={7}>
            <div className='row justify-content-between'>
              <div className='col-lg-5 col-xs-12 order-2 order-lg-1 mt-5'>
                  <div className='work-title mb-4'>
                    After the Revolution
                  </div>
                  <div className='work-subtitle d-flex pb-4'>
                      <BookIcon className='me-2' />
                      <div>
                        Forthcoming in
                        <a href='https://www.amazon.com/dp/1646052161?ref_=cm_sw_r_apin_dp_13TCZEH4PB5T99SV3TKC' target='_blank' className='ms-1'>
                        Até Mais: Latinx Futurisms
                          <LinkIcon className='ms-1'/>
                        </a>
                      </div>
                  </div>
                  <div className='body-content'>
                  Imagine a truly free life & tell us what that looks like.
                  </div>
              </div>
              <div className='col-lg-6 col-xs-12 order-1 order-lg-2 mt-lg-5'>
                <div id='player6'></div>
              </div>
            </div>
          </div>

          <div className="container" key={8}>
            <div className='row justify-content-between'>
              <div className='col-lg-5 col-xs-12 order-2 order-lg-1 mt-5'>
                <div className='work-title mb-4'>
                  & I repeat to myself
                </div>
                <div className='work-subtitle d-flex pb-4'>
                    <BookIcon className='me-2' />
                    <div>
                      Published in
                      <a href='https://www.barnesandnoble.com/w/poetry-as-spellcasting-tamiko-beyer/1142017301' target='_blank' className='ms-1'>
                        Poetry as Spellcasting (May 2023)
                        <LinkIcon className='ms-1'/>
                      </a>
                    </div>
                </div>
                <div className='body-content'>
                Find the ritual that affirms you in the way that authentically speaks to you.
                </div>
                <button type="button" className="modal-btn mt-4" data-bs-toggle="modal" data-bs-target="#repeat">
                  Read poem
                </button>
              </div>
              <div className='col-lg-6 col-xs-12 order-1 order-lg-2 mt-lg-5'>
                <img src={PoemImg2} className='img-fluid border rounded-4'></img>
              </div>
            </div>
          </div>
        </Slider>

        <Modal
          id='las-tias'
          title='A Conversation with the Tías'
          content={
          <div className="poem-body">
            <div className='container-fluid'>
              <div>
                <div className='row'>
                  <div className='col-6'>
                    “Buenos días,”
                  </div>
                  <div className='col-6'>
                    I am greeted by hot coffee blowing <br></br>
                    foggy clouds of caffeine into the air. <br></br>
                    A Black pool sweetened & savored <br></br>
                    since childhood. The arroz con gandules boiling <br></br>
                    to blend colors & textures rooted <br></br>
                    across continents. News reports battle <br></br>
                    salsa dancing between walls hoping <br></br>
                    this house of a world will grow <br></br> 
                    beyond machismo & a desire to be white. <br></br>
                    Headlines flash in scarlet <br></br> 
                  </div>
                </div>
              </div>
              <div>
                <div className='row'>
                  <div className='col-6'>
                    “Se intensifican protestas en Estados Unidos”
                  </div>
                  <div className='col-6'>
                  y de la nada this home becomes <br></br>
                  a sanctuary for supremacists. <br></br>
                  </div>
                </div>
              </div>
              <div>
                <div className='row'>
                  <div className='col-6'>
                    <p className='pt-4'>
                    “Black Lives Matter”
                    </p>
                    <p className='pt-4'>
                    "All Lives Matter”
                    </p>
                  </div>
                  <div className='col-6'>
                    My throat sandpaper from having <br></br>
                    flame <br></br>
                    from my mouth too many times. <br></br>
                    My ears muddy banks from the flooding of <br></br>
                    force <br></br>
                    its destruction through holy lands. <br></br>
                    My heart is a bomb ticking <br></br>
                    closer to eruption every time relatives insist <br></br>
                  </div>
                </div>
              </div>
              <div>
                <div className='row'>
                  <div className='col-12'>
                    "No somos negros."
                  </div>
                </div>
              </div>
              <div>
                <div className='row'>
                  <div className='col-12'>
                    & I repeat
                  </div>
                </div>
              </div>
              <div>
                <div className='row'>
                  <div className='col-12'>
                    "You are racist."
                  </div>
                </div>
              </div>
              <div>
                <div className='row justify-content-end'>
                  <div className='col-6'>
                    <p>
                    Skin attempting to crawl <br></br>
                    inside out de la vergüenza of how infected <br></br>
                    my people are. A plague playing <br></br>
                    with pueblos & países to ignore <br></br>
                    they are children of la esclavitud. <br></br>  
                    </p>
                    <p>
                    Dime, if saying Black lives matter disturbs <br></br>
                    so much, then what do you believe? <br></br>
                    Do you believe <br></br> 
                    skittles <br></br>
                    toy guns <br></br>
                    traffic <br></br>
                    jogging <br></br>
                    cigarettes <br></br>
                    living while Black <br></br>
                    are all reasons to be killed? <br></br>
                    Do you believe <br></br>
                    melanin is a sin because you fear <br></br>
                    me for dañando la raza? <br></br>
                    Do you believe <br></br>
                    Black lives do not matter? <br></br>  
                    </p>
                    <p>
                    Dime, if all lives matter, then why cut <br></br>
                    Black from your tongue, splitting <br></br>
                    yourself as you prove <br></br>
                    we both bleed red? Why gag <br></br> 
                    a people with backs broken <br></br>
                    into bridges for you to cross? Why act <br></br> 
                    like Brazil is not one of us? Why pretend <br></br> 
                    a klansman knows the difference between <br></br>
                    you & a Black person? <br></br>
                    </p>
                    <p>
                    Dime, si no somos negros, then quit <br></br> 
                    mofongo & sancocho addictions. <br></br>
                    Never again reminisce <br></br>
                    of Loíza’s beauty. You are forbidden from craving <br></br>
                    Bomba & Plena in any parranda. Stop <br></br> 
                    frying yucca like you created it. <br></br>
                    Celia Cruz is now banned. Forget <br></br>
                    Ochún, Changó, Yemayá, Obatalá <br></br>
                    when your white gods of a country leave <br></br>
                    you forsaken in inundated darkness. <br></br>
                    You cannot invoke deities you deny. <br></br>
                    Do not sing hymns of a free land <br></br>
                    as you step on the neck of a Black body. <br></br>
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <div className='row align-items-end'>
                  <div className='col-6'>
                  "Black Lives Matter"
                  </div>
                  <div className='col-6'>
                  Dime, if you are not racist, then accept <br></br>
                  Abuelita was Black as diamonds buried <br></br>
                  in Sierra Leone. Our hips inherited <br></br>
                  from women meant to outlast <br></br>
                  birthing, the sun, & no mercy. Our curls gifted <br></br>
                  by Abuelita’s seed. Our veins thickened <br></br>
                  by the forests of the Congo. So, if you are not racist, say <br></br>
                  until you are hoarse. <br></br>
                  </div>
                </div>
              </div>
              <div>
                <div className='row justify-content-end'>
                  <div className='col-6'>
                    <p>
                    Black lives mattered yesterday <br></br>
                    & they matter today <br></br>
                    & they will always matter <br></br>
                    & though my voice scrapes in pain from screaming <br></br>
                    Black bodies are not disposable, I will snap <br></br>
                    my vocal chords if it means a Black baby will grow <br></br>
                    to breathe in peace while Black. Because remember <br></br>
                    Latinidad is the fruit of rape, robbery, & slavery. <br></br>
                    There is nothing to romanticize with the erection <br></br>
                    of monuments for men who carved <br></br>
                    us to the bones. There is nothing to celebrate <br></br>
                    if you do not honor who gifted <br></br> 
                    you the tan skin you worship. We would not be <br></br>
                    without Black people. <br></br>
                    </p>
                    <p>
                      So repeat after me
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <div className='row'>
                  <div className='col-6'>
                    Black Lives Matter.
                  </div>
                </div>
              </div>
            </div>
          </div>
          }
        />

        <Modal 
          id='repeat'
          title='& I repeat to myself'
          content={
          <div className="poem-body">
            <p>
              I unstoppable <br></br>
              as the rose water marries <br></br>
              caribbean rum to become <br></br>
              holy. <br></br>
              the quartz crystal drowning <br></br>
              at the base of this homemade <br></br>
              agua florida hot to the touch & vibrating <br></br>
              ripples to circle the rim <br></br>
              of the bowl, of the glass. <br></br>
              of the jar that once housed <br></br>
              the fruit I left for Changó. <br></br>
            </p>
            <p>
              & I repeat to myself
            </p>
            <p>
            I gorgeous <br></br>
            as the lilies burst to perfume <br></br>
            the air. their orange cores staining <br></br>
            my hands like a palm reader’s fingers. <br></br>
            roses curve into daisies that lean <br></br>
            into mums. a bespoke bouquet blessed <br></br>
            by intent, while sunflowers reflect light bouncing <br></br> 
            between the candle & the cross <br></br>
            & the carving of La Caridad del Cobre. <br></br>
            </p>
            <p>
              & I repeat to myself
            </p>
            <p>
            I untouched <br></br>
            as I watch the wick coal <br></br>
            at my fingertips. flame flickering <br></br> 
            from ocean to lava <br></br>
            from sapphire to sunset. <br></br> 
            a single stream of smoke marks <br></br>
            the way to heaven as I speak <br></br>
            spirits into the room. broom <br></br>
            at the door. & I hear La Madama laugh. <br></br>
            </p>
          </div>
            
          }
        />

        <div id='slider-btn-group' className="container">
          <div className='row justify-content-center'>
            <button id='prev-btn' type="button" className="slider-btn col-5 col-lg-2" onClick={this.previous}>
              <div className='d-flex'>
                <ChevLeft className='me-2'/>
                <div>Prev</div>
              </div>
            </button>
            <button id='next-btn' type="button" className="slider-btn col-5 col-lg-2" onClick={this.next}>
              <div className='d-flex justify-content-end'>
                <div>Next</div>
                <ChevRight className='ms-2'/>
              </div>
            </button>
          </div>
        </div>
      </div>
    );
  }
}