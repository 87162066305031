import React, { Component } from "react";
import Slider from "react-slick";

import acentosLogo from './img/acentos-logo.png';
import apmLogo from './img/APM-logo.png';
import bbpLogo from './img/bbp-logo.png';
import dcScoresLogo from './img/dc-scores-logo.png';
import fsPressLogo from './img/flowersong-logo.png';
import smPressLogo from './img/shout-mouse-logo.png';
import vonaLogo from './img/VONA-logo.png';

export default class HomeSlider extends Component {
  render() {
    const settings = {
      dots: false,
      arrows: false,
      infinite: true,
      slidesToShow: 2,
      slidesToScroll: 1,
      autoplay: true,
      speed: 7000,
      autoplaySpeed: 1000,
      pauseOnHover: false,
      cssEase: 'linear'
    };
    return (
      <div>
        <Slider {...settings} className='partners d-flex py-3'>
          <div style={{width:240}}>
              <img src={acentosLogo} className="img-fluid"></img>
          </div>
          <div style={{width:240}}>
              <img src={apmLogo} className="img-fluid"></img>
          </div>
          <div style={{width:240}}>
              <img src={bbpLogo} className="img-fluid"></img>
          </div>
          <div style={{width:240}}>
              <img src={dcScoresLogo} className="img-fluid"></img>
          </div>
          <div style={{width:240}}>
              <img src={fsPressLogo} className="img-fluid"></img>
          </div>
          <div style={{width:240}}>
              <img src={smPressLogo} className="img-fluid"></img>
          </div>
          <div style={{width:240}}>
              <img src={vonaLogo} className="img-fluid"></img>
          </div>
        </Slider>
      </div>
    );
  }
}