import './Newsletter.css';
import {ReactComponent as MailIcon} from './img/mail.svg';
import {ReactComponent as OvalLeaf} from './img/oval leaf.svg';
import {ReactComponent as Leaves} from './img/leaves.svg';
import React, { useState } from 'react';
// import mailchimp from 'mailchimp-api-v3';

// const api_key = 'e324fac512570bdf4722aeef5c997d4f-us21';
// const list_id = 'abb137f0ba';

// const Mailchimp = new mailchimp(api_key);

function Newsletter() {
    // handleSubmit = (event) => {
    //     event.preventDefault();

    //     const email = this.state.email;

    //     const data = {
    //         email_address: email,
    //         status: 'subscribed'
    //     }

    //     Mailchimp.post('/lists/${list_id}/members', data)
    //         .then((response) => {
    //             console.log(response);
    //         })
    //         .catch((err) => {
    //             console.log(err);
    //         });
    // }

    return(
        <div id='newsletter' className='news'>
            <div className='container-fluid dark-section'>
                <div className='container'>
                    <div className='row '>
                        <div className='news-title d-flex mb-4'>
                            <MailIcon className='me-3' />
                            Stay connected
                        </div>
                        <div className='col-xl-6 col-12'>
                            <div className='emphasis mb-1'>
                                My Events Newsletter
                            </div>
                            <div className='body-content mb-5'>
                                Sign up and be the first to know about my upcoming live readings, book releases, or other special events.
                            </div>
                        </div>
                        <div className='col-xl-6 col-12'>
                            <form className='row justify-content-between'>
                                <div className="col-12 col-md-7">
                                    <label htmlFor="news-email" className="form-label">email address</label>
                                    <input type="email" name='email' className="form-control" id="news-email" aria-describedby="emailHelp"></input>
                                    <div id="emailHelp" className="form-text">Your email will never be shared with anyone else.</div>
                                </div>
                                <button type="submit" className="btn col-12 col-md-4 mt-5 mt-md-3">
                                    Sign up
                                </button>   
                            </form>
                        </div>
                    </div>
                </div>
                <OvalLeaf id='oval-leaf'/>
                <Leaves id='leaves'/>
            </div>
        </div>
        
    );
}

export default Newsletter;