import './Home.css';
import img1 from './img/img-1.png';
import {ReactComponent as RightArrowIcon} from './img/arrow-right.svg';
import HomeTitle from './HomeTitle.js';
import HomeSlider from './HomeSlider.js';

function Home() {
    return(
        <div id='home' className='mb-5'>
            <div className='container'>
                <div className='row justify-content-center'>
                    <div className='col-7'>
                        <img src={img1} className='main-img-mobile img-fluid'></img>
                    </div>
                </div>
            
                <div className='row'>
                    <div className='col-lg-6 col-xs-12 container'>
                        <div className='title'>
                            A voice that...
                        </div>
                        <div className='rotating-words mb-1'>
                            <HomeTitle />
                        </div>
                        <div className='subtitle row pb-4'>
                            <div className='col-4 col-md-3'>Reading</div>
                            <div className='col-1 text-center d-none d-md-block'>&bull;</div>
                            <div className='col-4 text-center'>Speaking</div>
                            <div className='col-1 text-center d-none d-md-block'>&bull;</div>
                            <div className='col-4 col-md-3 text-end'>Hosting</div>
                        </div>
                        <div className='body-content'>
                            As a poet, speaker, performer, teaching artist, and event host, I embrace my multi-faceted identity to craft the voice that speaks in my work. I aim to create mirrors reflecting the images I longed for so much in order to empower the marginalized people of color, women of color, young women of color, and so many others. Through my work, it's my focus to inspire <strong>them</strong> to believe enough in themselves to take action.
                        </div>
                        <a href='#contact' className="btn py-4 my-5 col-lg-6 col-12 d-flex align-items-center justify-content-center">
                            <span>
                                Book me today
                            </span> 
                            <RightArrowIcon className='ms-2'/>
                        </a>
                        <HomeSlider />
                    </div>
                    
                    <div className='col-lg-6 col-xs-12 d-flex justify-content-end'>
                        <img src={img1} className='main-img'></img>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;