import './Nav.css';
import {ReactComponent as Logo} from './img/tfr-logo-full.svg';

function Nav() {
    return (
        <nav className="navbar sticky-top navbar-expand-lg px-md-5 py-md-1 px-sm-1 py-sm-1" id='main-nav'>
            <div className="container justify-content-between">
                <a href="#">
                    <Logo className='App-logo'/>
                </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
                    <ul className="navbar-nav d-flex justify-content-evenly">
                        {/* <li className="nav-item mx-lg-1 mx-xl-3">
                            <a className="nav-link" aria-current="page" href="#newsletter">
                                Events
                            </a>
                        </li> */}
                        <li className="nav-item mx-lg-1 mx-xl-3">
                            <a className="nav-link" href="#about">
                                About
                            </a>
                        </li>
                        <li className="nav-item mx-lg-1 mx-xl-3">
                            <a className="nav-link" href="#works">
                                Works
                            </a>
                        </li>
                        <li className="nav-item mx-lg-1 mx-xl-3">
                            <a className="nav-link" href='#contact'>
                                Contact
                            </a>
                        </li>
                    </ul>
                    <a className="nav-btn px-5 py-3 ms-lg-5 mt-xs-4" type="button" href='https://sincerelytatiana.bigcartel.com/' target='_blank'>
                        Shop
                    </a>
                </div>
            </div>
        </nav>
    );
}

export default Nav;