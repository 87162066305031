import './App.css';
import Nav from './Nav.js';
import Home from './Home.js';
import Newsletter from './Newsletter.js';
import About from './About.js';
import Works from './Works.js';
import Contact from './Contact.js';
import Footer from './Footer.js';

function App() { return (
    <div className="App">
      <Nav />
      <Home />
      <About />
      <Works />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
