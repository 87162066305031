import React, { Component } from "react";
import Slider from "react-slick";

export default class HomeTitle extends Component {
  render() {
    const settings = {
      dots: false,
      arrows: false,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      speed: 1000,
      autoplaySpeed: 4000,
      cssEase: "ease",
      pauseOnHover: false,
      fade: true,
      draggable: false,
    };
    return (
      <div>
        <Slider {...settings}>
            <span>Inspires</span>
            <span>Empowers</span>
            <span>Grows</span>
            <span>Feels</span>
            <span>Dances</span>
            <span>Enchants</span>
            <span>Heals</span>
            <span>Paints</span>
            <span>Pushes</span>
            <span>Teaches</span>
            <span>Affirms</span>
            <span>Vibrates</span>
            <span>Honors</span>
            <span>Resonates</span>
        </Slider>
      </div>
    );
  }
}