import './Contact.css';
import imgContact from './img/img-contact.png';
import {ReactComponent as RightArrowIcon} from './img/arrow-right.svg';
import {ReactComponent as TwitterIcon} from './img/twitter icon.svg';
import {ReactComponent as IgIcon} from './img/ig icon.svg';
import {ReactComponent as FbIcon} from './img/fb icon.svg';

function Contact() {
    return(
        <div id='contact' className='container section end'>
            <div className='row justify-content-center'>
                <div className='col-7'>
                    <img src={imgContact} className='main-img-mobile img-fluid'></img>
                </div>
            </div>

            <div className='section-title'>Contact</div>

            <div className='row'>
                <div className='col-lg-5 col-12 d-flex'>
                    <img src={imgContact} className='main-img'></img>
                </div>
                <div className='col-lg-7 col-12'>
                    <div className='contact-title mt-4'>Let's talk</div>
                    <div className='body-content mb-5'>
                    I'm happy to discuss being a part of your spaces, future events, or collaborating on an upcoming project. I'm available for readings, speaking engagements, hosting opportunities, panel discussions, workshop facilitations, consultations, commissioned pieces and more.
                    <br></br><br></br>
                    Please use this form to tell me more.
                    </div>

                    <form action='contact.php' method='post'>
                        <div className="mb-3">
                            <label htmlFor="name" className="form-label">
                                name
                            </label>
                            <input type="text" className="form-control" id="name" name='name'></input>
                        </div>
                        <div className="mb-5">
                            <label htmlFor="email" className="form-label">
                                email address
                            </label>
                            <input type="email" className="form-control" id="email" name='email' aria-describedby="emailHelp"></input>
                            <div id="emailHelp" className="form-text">
                                Your email will never be shared with anyone else
                            </div>
                        </div>
                        <div className='mb-3'>what are you interested in taling about?</div>
                        <div className='container-fluid row mb-4'>
                            <div className='col-6'>
                                <div className="mb-3 form-check">
                                    <input 
                                        type="checkbox" className="form-check-input" id="option1" 
                                        value='a commissioned piece'
                                        name='interest[]'
                                    ></input>
                                    <label className="form-check-label" htmlFor="option1">
                                        a commissioned piece
                                    </label>
                                </div>
                                <div className="mb-3 form-check">
                                    <input 
                                        type="checkbox" className="form-check-input" id="option2" 
                                        value='live performance booking'
                                        name='interest[]'
                                    ></input>
                                    <label className="form-check-label" htmlFor="option2">
                                        live performance booking
                                    </label>
                                </div>
                            </div>
                            <div className='col-6'>
                                <div className="mb-3 form-check">
                                    <input 
                                        type="checkbox" 
                                        className="form-check-input" id="option3" 
                                        value='hosting an event'
                                        name='interest[]'
                                    ></input>
                                    <label className="form-check-label" htmlFor="option3">
                                        hosting an event
                                    </label>
                                </div>
                                <div className="mb-3 form-check">
                                    <input 
                                        type="checkbox" 
                                        className="form-check-input" 
                                        id="option4" 
                                        value='something else'
                                        name='interest[]'
                                    ></input>
                                    <label className="form-check-label" htmlFor="option4">
                                        something else
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className='mb-5'>
                            <label htmlFor='budget' className='form-label'>
                                budget
                            </label>
                            <select 
                                defaultValue={0} 
                                className="form-select" 
                                aria-label="budget options" 
                                id='budget'
                                name='budget'>
                                <option value='0'>Select your budget range</option>
                                <option value="150 - 500">$150 - $500</option>
                                <option value="500 - 1000">$500 - $1,000</option>
                                <option value="1000 - 2000">$1,000 - $2,000</option>
                                <option value="2000+">$2,000+</option>
                                <option value="not sure">I'm not sure</option>
                            </select>
                        </div>
                        <div className="mb-5">
                            <label htmlFor="message" className="form-label">
                                breif message
                            </label>
                            <textarea 
                                type='text' 
                                className="form-control" 
                                id="message"
                                name='message'
                            ></textarea>
                        </div>
                        <button 
                            type="submit" 
                            className="btn py-4 my-5 col-lg-6 col-12 d-flex align-items-center justify-content-center"
                            value='send message'
                        >
                            <span>
                                Send message
                            </span> 
                            <RightArrowIcon className='ms-2'/>
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Contact;